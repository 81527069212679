@import 'normalize.css';
@import './lib/base.css';

/* Templates */
@import './templates/home.css';
@import './templates/info.css';
@import './templates/issues.css';
@import './templates/countries.css';

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Roboto:400,700');

body {
  background-color: var(--pinkColor);
  color: var(--blackColor);
  font-family: Roboto, Sans-Serif;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
}

a,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: inherit;
}

/* More info */

.more-info {
  right: 0rem;
  position: absolute;
  top: 0rem;
  z-index: 1;
  cursor: pointer;
}

.more-info__text {
  border-bottom: 1px solid var(--blackColor);
  display: none;
  color: var(--blackColor);
  text-decoration: none;
}

.more-info__icon {
  background-color: var(--blackColor);
  color: var(--whiteColor) !important;
  display: block;
  height: 40px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  border-radius: 20px;
  font-size: 1.5em;
}

/* Header */

.header {
  position: relative;
  text-align: left;  
  width: 100%;
}

.header-text {
  font-family: Abril Fatface;
  letter-spacing: .06em; 
  background-color: var(--pinkColor);
  display: inline-block;
  font-size: var(--mediumFontSize);
  font-weight: normal;
  padding: 0 1.5rem;
  z-index: 100;

  &:hover {
    font-weight:bold;
  };
}

.header-line {
  background-color: var(--blackColor);
  margin-top: -.5px;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  height: 0px;
  z-index: -1;
}

/*  Main */

.container__main {
  display: inline-block;
  padding: 0 2rem;
}

.container-meta {
  /*display: none;*/
}

/* Extras */

.v-banner {
  font-family: Roboto;
  background-color: var(--blackColor);
  color: var(--whiteColor);
  font-size: .65em;
  padding: .4rem 1rem;
  letter-spacing: .02em;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

/**
 * Tablet and up
 */

@media (--tablet-up) {
  .header-line {
    height: 1px;
  }

  .header {
    text-align: center;  
  }

  .container__main {
    display: flex;
  }

  .more-info__icon {
    display: none;
  }

  .more-info__text {
    display: block;
  }

/*  .header {
    width:  calc(100% - 4rem);
    left: 2rem;
    right: 2rem;
  }*/

  .header-line {
    background-color: var(--blackColor);
    padding: 0 2rem;
  }

  .container-meta {
    display: block;
    width: 30%;
    padding: 0 1rem 0 0;
    margin-right: 1rem;
    border-right: solid 1px var(--blackColor);
  }
}
