@import '../lib/base.css';

.info {
  min-height: calc(100vh - 6rem);
  margin: 2rem 0;
  position: relative;
  width: 100%; 
}

.info__header {
  text-align: center;
  border-bottom: 1px solid var(--whiteColor);
  padding-bottom: 1rem;

  & img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.info__intro {
  /*@apply --narrow-container;*/
  width: 95%;
  margin-left: 2.5%;
  margin-top: 1.5rem;
  position: relative;
}

.info__intro-img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url('/assets/images/info-bg-bottom.svg');
  height: 15vh;
}

.info__intro-header {
  position: relative;
  text-align: center;  
  width: 100%;
}

.info__intro-header-text {
  font-family: Abril Fatface;
  letter-spacing: .06em; 
  background-color: var(--pinkColor);
  display: inline-block;
  font-size: 1.25rem;
  font-weight: normal;
  padding: 0 1.5rem;
  z-index: 100;
}

.info__intro-header-line {
  background-color: var(--blackColor);
  display: none;
  margin-top: -.5px;
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  z-index: -1;
}

.info__intro-text {
  font-size: 1em;
  width: 80%;
  margin: 0 10%;
}

.info__navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 4rem 2rem;
}

.info__navigation-item {
  text-align: center;

  & a {
    color: var(--blackColor);
    display: block;
  }

  & img {
    padding: 2rem 0;
  }
}

.info__navigation-item_by-issues {
  & img {
    max-width: 5%;
  }
}

.info__navigation-item_by-countries {
  & img {
    width: 50%;
  }
}

/**
 * Tablet and up
 */

@media (--tablet-up) {
  .info {
    /* align-items: center; */
    margin: 3rem 0;
  }

  .info__header {
    @apply --narrow-container-tablet-up;
    border-bottom: 0;
    
    & img {
      width: 100%;
    }
  }

  .info__intro {
    @apply --narrow-container-tablet-up;
  }

  .info__intro-img {
    background-image: url('/assets/images/info-bg-bottom-tablet-up.svg');
    height: 15vh;
  }

  .info__intro-text {
    /*font-size: var(--mediumFontSize);*/
  }

  .info__intro-header-text {
    font-size: 1.25rem;
    padding: 0 2rem;
  }

  .info__intro-header-line {
    display: inline-block;
  }

  .info__navigation {
    flex-direction: row;
  }

  .info__navigation-item_by-issues {
    & img {
      max-width: 15%;
    }
  }

  .info__navigation-item_by-countries {
    & img {
      width: 100%;
    }
  }
}
