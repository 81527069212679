@import '../lib/base.css';

.home {
  min-height: calc(100vh - 6rem);
  margin: 2rem 0;
  position: relative;
  width: 100%; 
}

.home__header {
  text-align: center;
  border-bottom: 1px solid var(--blackColor);
  padding-bottom: 1rem;

  & img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.home__intro {
  position: relative;
  @apply --narrow-container;
  margin-top: -1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  min-height: calc(100vh - 6rem);
}

.home__intro-container {
  min-height: calc(80vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.home__intro-images {
  height: 25vh;

  & img {
    height: 100%;
  } 
}

.home__intro-header {
  position: relative;
  text-align: center;  
  width: 100%;
  font-family: Abril Fatface;
  letter-spacing: .06em;
}

.home__intro-header-text {
  background-color: var(--pinkColor);
  display: inline-block;
  font-size: var(--mediumFontSize);
  font-weight: normal;
  padding: 0 1.5rem;
  z-index: 100;
}

.home__intro-header-line {
  background-color: var(--blackColor);
  display: none;
  margin-top: -.5px;
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  z-index: -1;
}

.home__intro-text {
  font-size: 1.4em;
  line-height: 1.5em;
}

.home__navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem 2rem;
}

.home__navigation-item {
  text-align: center;

  width: 200px;
  line-height: 50px;

  border: 1px solid var(--blackColor);
    
  box-shadow: 0px 11px 0px -4px var(--blackColor);
  transition: all .25s;
  text-transform: uppercase;

  &:hover{
    border-color: var(--greenColor);
    box-shadow: 0px 11px 0px -4px var(--greenColor);
    text-decoration: underline;
  }

  & a {
    display: block;
  }

  & img {
    padding: 2rem 0;
  }
}

.home__intro-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Tablet and up
 */

@media (--tablet-up) {
  .home {
    /* align-items: center; */
    margin: 3rem 0;
  }

  .home__header {
    @apply --narrow-container-tablet-up;
    border-bottom: 0;
    
    & img {
      width: 100%;
    }
  }

  .home__intro {
    @apply --narrow-container-tablet-up;
  }

  .home__intro-img {
    background-image: url('/assets/images/home-bg-bottom-tablet-up.svg');
    height: 15vh;
  }

  .home__intro-text {
    font-size: 1em;
  }

  .home__intro-header-text {
    font-size: 1.25rem;
    padding: 0 2rem;
  }

  .home__intro-header-line {
    display: inline-block;
  }

  .home__navigation {
    flex-direction: row;
  }

  .home__intro-content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
}
