/**
 * Structure
 */

.issues {
  min-height: calc(100vh - 3rem);
  margin: 1.5rem auto;
  position: relative;
  width: 95%; 
  max-width: 1280px;
}

/* Navigation */

.issues__navigation {
 /* & ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    list-style-type: none;
    padding: 0 2rem;
    margin: .3rem 0;
  }*/
  
  & ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 2rem;
    margin: .3rem 0;
  }

  & li {
    border: 1px solid var(--blackColor);
    font-size: var(--smallFontSize);
    margin: 0 0 2rem 0;
    max-width: 20rem;
    box-shadow: 0px 11px 0px -4px var(--blackColor);
    transition: all .25s;
    cursor: pointer;
  }

  & li:last-of-type {
    margin-right: 0;
  }

  & li a {
    color: var(--blackColor);
    display: inline-block;
    padding: .5rem;
    text-decoration: none;
    height:  100%;
  }

  & li:hover,
  & .is-selected {
    border-color: var(--greenColor);
    box-shadow: 0px 11px 0px -4px var(--greenColor);

    & a {
      text-decoration: underline;
    }
  }
}

/* Main */

.issues-meta__text,
.issues-meta__legend,
.issues-meta__unit,
.issues-meta__date,
.issues-meta__source {
  width: 100%;
  
  margin-bottom: 10px;
  padding-bottom: 10px;

  font-family: Abril Fatface;
  letter-spacing: .06em; 
  font-size: 1.1em;

  border-bottom: solid 1px var(--blackColor);

  & span {
    font-family: Roboto;
    letter-spacing: 0;
    font-size: .85em;
  }
}

.issues-meta__source {
  border-bottom: none;
}

.issues__charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
}

.issues-meta__by-country {
  bottom: 3rem;
  box-sizing: border-box;
  position: fixed;
  padding: 0 1rem;
  width: 20%;

  & img {
    margin-top: 1rem;
  }
}

/* Countries graphs */

.countries-graph {
  display: none;
}


/**
 * Charts
 */

/* 0 */

.chart-0,
.chart-1,
.chart-2,
.chart-3,
.chart-4 {
  padding: 20px 20px;
  text-align: center;
  width: 80px;
  transition: all .2s ease;
  cursor: pointer;
  pointer-events: none;
}

.chart-2,
.chart-3 {
  padding: 20px 10px;
  width: 104px;
}

.chart-0-chart {
  clip-path: circle(80px at center);
}

.chart-0-bg {
  fill: var(--lightGreenColor);
}

.chart-0-bar {
  fill: var(--greenColor);
}

.chart-0-country,
.chart-1-country,
.chart-2-country,
.chart-3-country,
.chart-4-country  {
  margin: .5rem 0 .125rem 0;
  color: var(--blackColor);
  font-size: 0.65em;
  font-weight: 700;
  text-transform: uppercase;
}

.chart-0-value,
.chart-1-value,
.chart-2-value,
.chart-3-value,
.chart-4-value   {
  color: var(--darkGreenColor);
  font-family: Abril Fatface;
  letter-spacing: .1em;
  font-size: .85em;
}

.chart-2-value {
  font-size: .7em;
}

/* 1 */

.chart-1-circle {
  fill: var(--darkGreenColor);
}

/* 3 */
.chart-3-heart {
  fill: var(--pinkColor);
  stroke: var(--blackColor);
  stroke-miterlimit: 10;
  transition: all .5s ease-in-out;

  &.is-filled {
    fill: var(--greenColor);
    stroke: var(--greenColor);
  }
}

/* 4 */

.chart-4-drop {
  fill: var(--greenColor);
  transform-origin: center;
}

/**
 * Tablet and up
 */

@media (--tablet-up) {
  .issues__charts {

    justify-content: flex-start;

  }

  .issues__navigation {
    & ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0 2rem;
      margin: .3rem 0;
    }
  }

  [data-sel="selected"] {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 1);
    opacity: 1;
  }

  [data-sel="un-selected"] {
    opacity: 0.2;
  }
  
  .chart-0,
  .chart-1,
  .chart-2,
  .chart-3,
  .chart-4 {
    pointer-events: auto;
  }  

  .chart-0:hover,
  .chart-1:hover,
  .chart-2:hover,
  .chart-3:hover,
  .chart-4:hover {
    transform: scale(1.1);
  }  
  
  .issues__navigation { 
    & li {
      margin: 0 2rem 2rem 0;
    }
  }

  .issues__charts {
    width: 80%;
  }  
}
