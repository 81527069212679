.countries {
  min-height: 100vh;
  margin: 24px 0;
  margin: 1.5rem 0;
  position: relative;
  width: 100%;
}

.countries-meta {
  width: 20%;
  
  & .countries-meta__title {

  }

  & [data-el=countries-navigation] {
    display: block;
    margin: 0 0 10px;

    color: var(--darkGreenColor);
    text-transform: uppercase;

    &:hover {
      font-weight:bold;
    }

    &:visited {
      color: var(--darkGreenColor);
    };
  }

  & .is-selected {
    font-weight: bold;
    text-decoration: underline;
  }
}

.countries-graph {
  position: relative;
  padding: 5%;

  border: solid 1px var(--blackColor); 

  & .graph__title h2{
    margin: 0;
    text-transform: uppercase;
    font-size: 1em;
  }
}

.countries-graph .chart-0,
.countries-graph .chart-1,
.countries-graph .chart-2,
.countries-graph .chart-3,
.countries-graph .chart-4 {
    pointer-events: none;
}

.countries__graph-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.countries-button {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  top: 0;

  border-left: solid 1px var(--blackColor);
  border-bottom: solid 1px var(--blackColor);
  background-image: url('../../assets/images/close.svg');
  background-size: 13.5px;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  cursor: pointer;

  &:hover{
    background-color: var(--blackColor);
    background-image: url('../../assets/images/close_white.svg');
  };
}

[data-el="countries-chart"] {
  width: 50%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  

  & p {
    display: block;
    width: 100%;
    margin: 20px 0 0 0;

    text-align: center;
    font-size: 0.8em;
  }

  & .chart {
      width: auto;
      margin: 0;
    }
}



