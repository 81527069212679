:root {

  /* Colors */
  --blackColor: #0F0F0F;
  --whiteColor: #FFFFFF;
  --darkGreyColor: #7f7f7f;
  --pinkColor: #EDDBC3;
  --greenColor: #39A989;
  --lightGreenColor: #B8C4A8;
  --darkGreenColor: #2B775F;

  /* Typography */
  --largeFontSize: calc(24 / 16 * 1rem);
  --mediumFontSize: calc(20 / 16 * 1rem);
  --baseFontSize: calc(16 / 16 * 1rem);
  --smallFontSize: calc(14 / 14 * 1rem);
  --extraSmallFontSize: calc(12 / 16 * 1rem);
  --ultraSmallFontSize: calc(10 / 16 * 1rem);

  /* Sizes */
  --narrowContainerWidthTabletUp: calc(100 / (1280 / 640) * 1%);
  --narrowContainerWidth: 80%;

  /* Media queries */
  @custom-media --tablet-up (min-width: 1024px);
  
  /* Sets */

  --narrow-container: {
    width: var(--narrowContainerWidth);
    margin-left: auto;
    margin-right: auto;
  };

  --narrow-container-tablet-up: {
    width: var(--narrowContainerWidthTabletUp);
    margin-left: auto;
    margin-right: auto;
  };

}

